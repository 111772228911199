import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Password from "../Login/Password";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Header = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleLogout = () => setDropdownOpen((prevState) => !prevState);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { isAuth, user } = useContext(AuthContext);
  const [modalPassword, setModalPassword] = useState(false);
  const togglePassword = () => setModalPassword(!modalPassword);

  const [dropdownOpenIdioma, setDropdownOpenIdioma] = useState(false);
  const toggleIdioma = () => setDropdownOpenIdioma((prevState) => !prevState);
  const [t, i18n] = useTranslation("global");
  return (
    <>
      {isAuth ? (
        <header className="sticky-top">
            <Navbar color="dark" dark expand="lg">
              <a href="/Inicio">
                <img
                  src={process.env.PUBLIC_URL + "/LogoSistemify2RS.png"}
                  height="50 px"
                  alt="logo"
                />
              </a>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>

                   {user.menuCatalogos  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuCatalogos">
                        {t("CATALOGOS")}
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      {t("CATALOGOS")}
                    </NavLink>
                  )}

                  {user.menuContenedores || user.menuContenedoresPS  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuContenedores">
                        {t("CONTENEDORES")}
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      {t("CONTENEDORES")}
                    </NavLink>
                  )}

                {user.menuAdmin  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuAdmin">
                        {t("ADMIN")}
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      {t("ADMIN")}
                    </NavLink>
                  )}
                  {user.menu_gastos  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuGastos">
                        {t("COSTO IMPORTACION")}
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      {t("COSTO IMPORTACION")}
                    </NavLink>
                  )} 

              {/* {user.menuReportes  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuReportes">
                        {t("REPORTES")}
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      {t("REPORTES")}
                    </NavLink>
                  )} */}

                  {user.menuReportes  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuReportes">
                        {t("REPORTES")}
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      {t("REPORTES")}
                    </NavLink>
                  )}
                   
                  {user.menu_usuarios  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/ListadoUsuarios">
                        {t("USUARIOS")}
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      {t("USUARIOS")}
                    </NavLink>
                  )}
                  
                  {user.menu_logs  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuLogs">
                        LOGS
                      </NavLink>
                    </NavItem>
                  ) : undefined}

                </Nav>
                <NavbarText>
                  <NavLink className="titulosNav">
                    <Dropdown direction="left" isOpen={dropdownOpen} toggle={toggleLogout}>
                      <DropdownToggle color="link">
                        <i class="fas fa-user-cog fa-2x"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem href="/logout">Logout</DropdownItem>
                        <DropdownItem onClick={togglePassword}>Password</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </NavLink>
                </NavbarText>

                <NavbarText>
              <NavLink>
                <Dropdown direction="left" isOpen={dropdownOpenIdioma} toggle={toggleIdioma}>
                  <DropdownToggle color="link">
                    <i class="fas fa-globe fa-2x"></i>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      onClick={() => {
                        localStorage.setItem("idioma", "es");
                        i18n.changeLanguage("es");
                      }}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/flagES.png"}
                        height="15 px"
                        width="30 px"
                      /> ES
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        localStorage.setItem("idioma", "en");
                        i18n.changeLanguage("en");
                      }}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/flagEN.png"}
                        height="15 px"
                        width="30 px"
                      /> EN
                    </DropdownItem>
                  
                  </DropdownMenu>
                </Dropdown>
              </NavLink>
            </NavbarText>

              </Collapse>
            </Navbar>

            
            <Modal size="sm" isOpen={modalPassword} toggle={togglePassword}>
                  <ModalHeader toggle={togglePassword}>
                    <h4>Cambio de contraseña</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Password />
                  </ModalBody>
                </Modal>
          
        </header>
      ) : undefined}
    </>
  );
};

export default Header;
