import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function ListadoContenedoresPS() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_CONTENEDORES = process.env.REACT_APP_URL_CONTENEDORES;
  const URL_ARTICULOS_CONTENEDOR = process.env.REACT_APP_URL_ARTICULOS_CONTENEDOR;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_BROKERS = process.env.REACT_APP_URL_BROKERS;
  const URL_FORWARDERS = process.env.REACT_APP_URL_FORWARDERS;
  const URL_NAVIERAS = process.env.REACT_APP_URL_NAVIERAS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_UPMEDIA = process.env.REACT_APP_URL_UPMEDIA;
  const URL_UPMEDIA_PACKING = process.env.REACT_APP_URL_UPMEDIA_PACKING;
  const URL_UPMEDIA_BL = process.env.REACT_APP_URL_UPMEDIA_BL;
  const URL_DELMEDIA = process.env.REACT_APP_URL_DELMEDIA;
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;
  const [t, i18n] = useTranslation("global");
  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 91);

  let comp30 = new Date();
  comp30.setDate(comp30.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("65567d42bffbcf0019e1fdf4");
  const [brokers, setBrokers] = useState([]);
  const [selectedBroker, setSelectedBroker] = useState("");
  const [forwarders, setForwarders] = useState([]);
  const [selectedForwarder, setSelectedForwarder] = useState("");
  const [navieras, setNavieras] = useState([]);
  const [selectedNaviera, setSelectedNaviera] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [selectedSurtido, setSelectedSurtido] = useState("");
  // const [selectedEnMar, setSelectedEnMar] = useState("");
  const [selectedPrevio, setSelectedPrevio] = useState("");
  const [selectedPL, setSelectedPL] = useState("");
  const [selectedBL, setSelectedBL] = useState("");
  const [selectedActivo, setSelectedActivo] = useState("");

  const [fecha, setFecha] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [totalGeneral, setTotalGeneral] = useState("");
  const [totalCantidad, setTotalCantidad] = useState("");
  const [numeroContenedor, setNumeroContenedor] = useState("");
  const [rastreo, setRastreo] = useState("No");
  const [buque, setBuque] = useState("");
  const [prioridad, setPrioridad] = useState("");

  const [idEdit, setIdEdit] = useState("");
  const [selectedClienteEdit, setSelectedClienteEdit] = useState("65567d42bffbcf0019e1fdf4");
  const [selectedBrokerEdit, setSelectedBrokerEdit] = useState("");
  const [selectedForwarderEdit, setSelectedForwarderEdit] = useState("");
  const [selectedNavieraEdit, setSelectedNavieraEdit] = useState("");

  const [coordenadasMapa, setCoordenadasMapa] = useState([]);
  const [originCoords, setOriginCoords] = useState([]);
  const [destinationCoords, setDestinationCoords] = useState([]);
  const [vesselCoords, setVesselCoords] = useState([]);
  const [diasLlegada, setDiasLlegada] = useState(0);

  const [modalMapa, setModalMapa] = useState(false);
  const toggleMapa = () => setModalMapa(!modalMapa);

  const [flete, setFlete] = useState("");
  const [incrementable, setIncrementable] = useState("");
  const [numeroReferencia, setNumeroReferencia] = useState("");
  const [referenciaExtra, setReferenciaExtra] = useState("");
  const [naviera, setNaviera] = useState("");
  const [referencia, setReferencia] = useState("");
  const [fechaETD, setFechaETD] = useState("");
  const [fechaETAEstimada, setFechaETAEstimada] = useState("");
  const [fechaETAReal, setFechaETAReal] = useState("");
  const [fechaLlegada, setFechaLlegada] = useState("");
  const [fechaLiberacion, setFechaLiberacion] = useState("");
  const [fechaEntregaEstimada, setFechaEntregaEstimada] = useState("");
  const [fechaEntregaReal, setFechaEntregaReal] = useState("");
  const [aduana, setAduana] = useState("");

  const [photo, setPhoto] = useState([]);
  const [idContenedor, setIdContenedor] = useState();
  const [file, setFile] = useState([]);
  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);
  const [modalSubirFoto, setModalSubirFoto] = useState(false);
  const toggleSubirFoto = () => setModalSubirFoto(!modalSubirFoto);
  const [modalSubirFotoPacking, setModalSubirFotoPacking] = useState(false);
  const toggleSubirFotoPacking = () => setModalSubirFotoPacking(!modalSubirFotoPacking);
  const [modalFotoPacking, setModalFotoPacking] = useState(false);
  const toggleFotoPacking = () => setModalFotoPacking(!modalFotoPacking);
  const [photoFormat, setPhotoFormat] = useState("");
  const [PLPDF, setPLPDF] = useState("");

  const [modalSubirFotoBL, setModalSubirFotoBL] = useState(false);
  const toggleSubirFotoBL = () => setModalSubirFotoBL(!modalSubirFotoBL);
  const [modalFotoBL, setModalFotoBL] = useState(false);
  const toggleFotoBL = () => setModalFotoBL(!modalFotoBL);

  const [articulosContenedor, setArticulosContenedor] = useState([]);
  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [totalGeneral_edit, setTotalGeneralEdit] = useState(0);
  const [totalCantidad_edit, setTotalCantidadEdit] = useState(0);

  const [totalCaptura, setTotalCaptura] = useState(1);

  // const [inputFields, setInputFields] = useState([]);
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 0,
      descripcion: "NA",
      proveedor: "NA",
      marca: "NA",
      PO: 0,
      CBMS: 0,
      precio: 0,
      total: 0,
    },
  ]);

  const [inputFieldsFlete, setInputFieldsFlete] = useState([]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalFlete, setModalFlete] = useState(false);
  const toggleFlete = () => setModalFlete(!modalFlete);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);


  // Status
  const [modalStatus, setModalStatus] = useState(false);
  const toggleStatus = () => setModalStatus(!modalStatus);
  const [id, setId] = useState('');
  const [statusSeguimiento, setStatusSeguimiento] = useState('');
  const [mar, setMar] = useState('');
  const [fechaMar, setFechaMar] = useState('');
  const [observacionesMar, setObservacionesMar] = useState('');
  const [piso, setPiso] = useState('');
  const [fechaPiso, setFechaPiso] = useState('');
  const [observacionesPiso, setObservacionesPiso] = useState('');
  const [revision, setRevision] = useState('');
  const [fechaRevision, setFechaRevision] = useState('');
  const [observacionesRevision, setObservacionesRevision] = useState('');
  const [modulacion, setModulacion] = useState('');
  const [fechaModulacion, setFechaModulacion] = useState('');
  const [observacionesModulacion, setObservacionesModulacion] = useState('');
  const [libre, setLibre] = useState('');
  const [fechaLibre, setFechaLibre] = useState('');
  const [observacionesLibre, setObservacionesLibre] = useState('');


  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;
  useMemo(() => {
    if (user.tipo == "Forwarder") {
      setSelectedForwarder(user.forwarders);
    }

    if (user.tipo == "Broker") {
      setSelectedBroker(user.brokers);
    }

    axios
      .get(
        `${URL_CONTENEDORES}Fechas/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allContenedores = response.data;
        // Array para pagination
        let arrayTabla = allContenedores
          .sort((a, b) => (a.idContenedor < b.idContenedor ? 1 : -1))
          .filter((a) => a.clientes[0]._id == "65567d42bffbcf0019e1fdf4")
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idContenedor,
              activo: a.is_active,
              fecha: a.fecha,
              observaciones: a.observaciones,
              totalGeneral: a.totalGeneral,
              naviera: a.navieras[0].name,
              idNaviera: a.navieras[0]._id,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              brokers: a.brokers[0].name,
              idBrokers: a.brokers[0]._id,
              forwarders: a.forwarders[0].name,
              idForwarders: a.forwarders[0]._id,
              totalCantidad: a.totalCantidad,
              totalSurtido: a.totalSurtido,
              pendienteSurtir: a.pendienteSurtir,
              numeroReferencia: a.numeroReferencia,
              referenciaExtra: a.referenciaExtra,
              referencia: a.referencia,
              etd: a.etd,
              etaEstimada: a.etaEstimada,
              etaReal: a.etaReal,
              pl: a.pl,
              totalGastos: a.totalGastos,
              fechaLlegada: a.fechaLlegada,
              fechaLiberacion: a.fechaLiberacion,
              fechaEntregaEstimada: a.fechaEntregaEstimada,
              fechaEntregaReal: a.fechaEntregaReal,
              aduana: a.aduana,
              // enMar: a.enMar,
              surtido: a.surtido,
              previo: a.previo,
              packing: a.packing,
              bl: a.bl,
              flete: a.flete,
              incrementable: a.incrementable,
              buque: a.buque,
              prioridad: a.prioridad,

              
              rastreo:a.rastreo,
              mapa:a.mapa,
              fromCountry: a.fromCountry,
              polOrigen: a.polOrigen,
              loadingDate: a.loadingDate,
              departureDate: a.departureDate,
              toCountry: a.toCountry,
              podDestino: a.podDestino,
              arrivalDate: a.arrivalDate,
              dischargeDate: a.dischargeDate,
              vessel: a.vessel,
              vesselIMO: a.vesselIMO,
              vesselLatitude: a.vesselLatitude,
              vesselLongitude: a.vesselLongitude,
              vesselVoyage: a.vesselVoyage,
              polLatitude: a.polLatitude,
              polLongitude: a.polLongitude,
              podLatitude: a.podLatitude,
              podLongitude: a.podLongitude,
              vesselLatitude: a.vesselLatitude,
              vesselLongitude: a.vesselLongitude,

              statusSeguimiento: a.statusSeguimiento,
              mar: a.mar,
              fechaMar: a.fechaMar,
              observacionesMar: a.observacionesMar,
              piso: a.piso,
              fechaPiso: a.fechaPiso,
              observacionesPiso: a.observacionesPiso,
              revision: a.revision,
              fechaRevision: a.fechaRevision,
              observacionesRevision: a.observacionesRevision,
              modulacion: a.modulacion,
              fechaModulacion: a.fechaModulacion,
              observacionesModulacion: a.observacionesModulacion,
              libre: a.libre,
              fechaLibre: a.fechaLibre,
              observacionesLibre: a.observacionesLibre,

            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_CLIENTES}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_BROKERS}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBrokers = response.data;
        setBrokers(allBrokers);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_FORWARDERS}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allForwarders = response.data;
        setForwarders(allForwarders);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${URL_NAVIERAS}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allNavieras = response.data;
        setNavieras(allNavieras);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, selectedFechaInicio, selectedFechaFin, selectedForwarder]);

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedBroker == 0 || selectedBroker == a.idBrokers) &&
        (selectedForwarder == 0 || selectedForwarder == a.idForwarders) &&
        (selectedNaviera == 0 || selectedNaviera == a.idNaviera)&&
        (selectedSurtido == 0 || selectedSurtido == a.surtido)&&
        (selectedPrevio == 0 || selectedPrevio == a.previo)&&
        (selectedPL == 0 || selectedPL == a.packing) &&
        (selectedBL == 0 || selectedBL == a.bl) &&
        (selectedActivo == 0 || selectedActivo == a.activo)
      ) {
        return [
          a.numero,
          a.fecha,
          a.numeroReferencia,
          a.referenciaExtra,
          a.referencia,
          a.naviera,
          a.buque,
          a.vessel,
          a.clientes,
          // a.brokers,
          a.forwarders,
          // a.observaciones,
          // a.enMar,
          a.previo,
          a.surtido,
          // a.flete,
          new Intl.NumberFormat("en-US").format(a.totalCantidad),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoPriceShoes.png";
    doc.addImage(img2, "png", 120, 15, 65, 28);
    doc.text(`Listado Contenedores`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Referencia",
          "Ref Extra",
          "Contenedor",
          "Naviera",
          "Buque",
          "Transbordo",
          "Cliente",
          // "Broker",
          "Forwarder",
          // "Observaciones",
          // "Mar",
          "Previo",
          "Surtido",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Contenedores.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedBroker == 0 || selectedBroker == a.idBrokers) &&
        (selectedForwarder == 0 || selectedForwarder == a.idForwarders) &&
        (selectedNaviera == 0 || selectedNaviera == a.idNaviera)&&
        (selectedSurtido == 0 || selectedSurtido == a.surtido)&&
        (selectedPrevio == 0 || selectedPrevio == a.previo)&&
        (selectedPL == 0 || selectedPL == a.packing)&&
        (selectedBL == 0 || selectedBL == a.bl)&&
        (selectedActivo == 0 || selectedActivo == a.activo)
      ) {
        return {
          Id: a._id,
          Numero: a.numero,
          Fecha: a.fecha,
          Referencia: a.numeroReferencia,
          // RefExtra: a.referenciaExtra,
          Contenedor: a.referencia,
          ETD: a.etd,
          ETAEst: a.etaEstimada,
          ETAReal: a.etaReal,
          FechaEntrega: a.fechaEntregaReal,
          Naviera: a.naviera,
          Buque: a.buque,
          Transbordo: a.vessel,
          // Cliente: a.clientes,
          // Broker: a.brokers,
          Forwarder: a.forwarders,
          // Observaciones: a.observaciones,
          // Mar: a.enMar,
          Previo: a.previo,
          Surtido: a.surtido,
          // Flete: a.flete,
          Cantidad: a.totalCantidad,
          Prioridad: a.prioridad
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoContenedoresPS";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoContenedoresPS",
        sheetFilter: [
          "Id",
          "Numero",
          "Fecha",
          "Referencia",
          // "RefExtra",
          "Contenedor",
          "ETD",
          "ETAEst",
          "ETAReal",
          "FechaEntrega",
          "Naviera",
          "Buque",
          "Transbordo",
          // "Cliente",
          // "Broker",
          "Forwarder",
          // "Observaciones",
          // "Mar",
          "Previo",
          "Surtido",
          // "Flete",
          "Cantidad",
          "Prioridad"
        ],
        sheetHeader: [
          "Id",
          "Numero",
          "Fecha",
          "Referencia",
          // "RefExtra",
          "Contenedor",
          "ETD",
          "ETAEst",
          "ETAReal",
          "FechaEntrega",
          "Naviera",
          "Buque",
          "Transbordo",
          // "Cliente",
          // "Broker",
          "Forwarder",
          // "Observaciones",
          // "Mar",
          "Previo",
          "Surtido",
          // "Flete",
          "Cantidad",
          "Prioridad"
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedBroker == 0 || selectedBroker == a.idBrokers) &&
        (selectedForwarder == 0 || selectedForwarder == a.idForwarders) &&
        (selectedNaviera == 0 || selectedNaviera == a.idNaviera)&&
        (selectedSurtido == 0 || selectedSurtido == a.surtido)&&
        (selectedPrevio == 0 || selectedPrevio == a.previo)&&
        (selectedPL == 0 || selectedPL == a.packing)&&
        (selectedBL == 0 || selectedBL == a.bl)&&
        (selectedActivo == 0 || selectedActivo == a.activo)
      ) {
        return [
          a.numero,
          a.fecha,
          a.numeroReferencia,
          a.referenciaExtra,
          a.referencia,
          a.naviera,
          a.buque,
          a.vessel,
          a.clientes,
          // a.brokers,
          a.forwarders,
          // a.observaciones,
          // a.enMar,
          a.previo,
          a.surtido,
          new Intl.NumberFormat("en-US").format(a.totalCantidad),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoPriceShoes.png";
    doc.addImage(img2, "png", 120, 15, 65, 28);
    doc.text(`Listado Contenedores`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Referencia",
          "Ref Extra",
          "Contenedor",
          "Naviera",
          "Buque",
          "Transbordo",
          "Cliente",
          // "Broker",
          "Forwarder",
          // "Observaciones",
          // "Mar",
          "Previo",
          "Surtido",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Contenedores",
          email: mailTo,
          fileName: "ListadoContenedoresPS.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoPriceShoes.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Contenedores.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

      function importarArchivo(event){
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let hojas = [];
   
    if (name === "file") {
  
      let reader = new FileReader();
        reader.readAsArrayBuffer(target.files[0]);
        reader.onloadend = (e) => {
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, { type: "array" });
  
          workbook.SheetNames.forEach(function (sheetName) {
            // Here is your object
            var XL_row_object = XLSX.utils.sheet_to_json(
              workbook.Sheets[sheetName]
            );
            hojas.push({
              data: XL_row_object,
              sheetName,
            });
          });
  
          let A1 = hojas[0].data
  
          let TC = totalCaptura
          A1.map((a)=>{
             TC = TC + 1
            setTotalCaptura(TC)
          inputFieldsFlete.push({
            id: uuidv4(),
            idCont: a.Id,
            prioridad: a.Prioridad
          })
          })
  
          // ActualizaTotales()
        }
    }
  }
console.log("hola",inputFieldsFlete)

  function jalaInfo(
    fecha,
    idClientes,
    idBrokers,
    idForwarders,
    totalCantidad,
    totalGeneral,
    observaciones,
    numeroContenedor,
    numeroReferencia,
    referenciaExtra,
    flete,
    incrementable,
    idNaviera,
    buque,
    prioridad,
    referencia,
    etd,
    etaEstimada,
    etaReal,
    fechaLlegada,
    fechaLiberacion,
    fechaEntregaEstimada,
    fechaEntregaReal,
    aduana,
    rastreo,
    idEdit
  ) {
    setFecha(fecha);
    setSelectedClienteEdit(idClientes);
    setSelectedBrokerEdit(idBrokers);
    setSelectedForwarderEdit(idForwarders);
    setTotalCantidad(totalCantidad);
    setTotalGeneral(totalGeneral);
    setObservaciones(observaciones);
    setNumeroContenedor(numeroContenedor);
    setNumeroReferencia(numeroReferencia);
    setFlete(flete);
    setIncrementable(incrementable);
    setReferenciaExtra(referenciaExtra);
    setSelectedNavieraEdit(idNaviera);
    setBuque(buque);
    setPrioridad(prioridad);
    setReferencia(referencia);
    setFechaETD(etd);
    setFechaETAEstimada(etaEstimada);
    setFechaETAReal(etaReal);
    setFechaLlegada(fechaLlegada);
    setFechaLiberacion(fechaLiberacion);
    setFechaEntregaEstimada(fechaEntregaEstimada);
    setFechaEntregaReal(fechaEntregaReal);
    setAduana(aduana);
    setRastreo(rastreo);
    setIdEdit(idEdit);
    toggleEdit();
  }

  async function PDFOC(
    fecha,
    clientes,
    brokers,
    forwarders,
    totalCantidad,
    totalGeneral,
    observaciones,
    numero,
    naviera,
    idPDFOC
  ) {
    await axios
      .get(`${URL_ARTICULOS_CONTENEDOR}/contenedor/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosContenedor = response.data;
        let arrayTabla = allArticulosContenedor
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].name,
              descripcion: a.descripcion,
              cantidad: a.cantidad,
              precio: a.precio,
              total: a.total,
              pendienteSurtir: a.pendienteSurtir,
              cantidadSurtido: a.cantidadSurtido,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let cant = inputFields.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotalCantidad(TC);
        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);

        const data = dataFinal.map((a) => {
          return [
            a.articulos,
            a.descripcion,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            "$" + new Intl.NumberFormat("en-US").format(a.precio),
            "$" + new Intl.NumberFormat("en-US").format(a.total),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoPriceShoes.png";
        doc.addImage(img2, "png", 120, 15, 45, 15);
        doc.text(`Contenedor # ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Naviera ${naviera}`, 20, 30);
        doc.text(
          `Piezas ${new Intl.NumberFormat("en-US").format(totalCantidad)}`,
          20,
          35
        );
        doc.autoTable({
          head: [["Articulo", "Descripcion", "Cantidad", "Precio", "Total"]],
          body: dataPDFLimpia,
          startY: 40,
          foot: [
            [
              "",
              "",
              "",
              "Total",
              "$" + new Intl.NumberFormat("en-US").format(totalGeneral),
            ],
          ],
          showFoot: "lastPage",
        });
        doc.save(`Contenedor-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function editContenedores(event) {
    event.preventDefault();
    const URL_CONTENEDORES_EDIT = `${process.env.REACT_APP_URL_CONTENEDORES}/${idEdit}`;

    axios
      .patch(
        URL_CONTENEDORES_EDIT,
        {
          fecha,
          // clientes: selectedClienteEdit,
          brokers: selectedBrokerEdit,
          forwarders: selectedForwarderEdit,
          observaciones,
          numeroReferencia,
          flete,
          incrementable,
          referenciaExtra,
          navieras: selectedNavieraEdit,
          buque,
          prioridad,
          referencia,
          etd: fechaETD,
          etaEstimada: fechaETAEstimada,
          etaReal: fechaETAReal,
          fechaLlegada: fechaLlegada,
          fechaLiberacion: fechaLiberacion,
          fechaEntregaEstimada: fechaEntregaEstimada,
          fechaEntregaReal: fechaEntregaReal,
          aduana,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Contenedor",
            detalle: `${numeroContenedor} ${fecha}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function guardaFlete(event) {
    event.preventDefault();

    let totalFlete = inputFieldsFlete.length



    inputFieldsFlete.map((a) => {
      axios
        .patch(
          `${URL_CONTENEDORES}/${a.idCont}`,
          {
            prioridad: a.prioridad
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem(
                "app_token"
              )}`,
            },
          }
        )
        .then(() => {
          totalFlete = totalFlete - 1;
          if (totalFlete == 0) {
            axios
              .post(
                URL_LOGS,
                {
                  tipo: "Editar Flete",
                  detalle: `Subir Fletes`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                Swal.fire("Good job!", "Creado con exito", "success");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    });
  }

  const headers = [
    { name: `${t("Numero")}`, field: "numero", sortable: true },
    { name: `${t("Fecha")}`, field: "fecha", sortable: true },
    { name: `${t("No. Referencia")}`, field: "numeroReferencia", sortable: true},
    { name: `${t("Referencia Extra")}`, field: "referenciaExtra", sortable: true },
    { name: `${t("Contenedor")}`, field: "referencia", sortable: true },
    { name: `${t("Naviera")}`, field: "naviera", sortable: true },
    { name: `${t("Buque")}`, field: "buque", sortable: true },
    { name: `${t("Transbordo")}`, field: "vessel", sortable: true },
    // { name: `${t("Cliente")}`, field: "clientes", sortable: true },
    // { name: `${t("Broker")}`, field: "brokers", sortable: true },
    { name: `${t("Forwarder")}`, field: "forwarders", sortable: true },
    { name: `${t("ETD")}`, field: "etd", sortable: true },
    { name: `${t("ETA Estimada")}`, field: "etaEstimada", sortable: true },
    { name: `${t("ETA")}`, field: "etaReal", sortable: true },
    { name: `${t("Cantidad")}`, field: "totalCantidad", sortable: true },
    // { name: `${t("Flete")}`, field: "flete", sortable: true },
    // { name: `${t("Incrementable")}`, field: "incrementable", sortable: true },
    { name: `${t("Entregado")}`, field: "surtido", sortable: false },
    { name: `${t("Status")}`, field: "statusSeguimiento", sortable: false },
    { name: `${t("Fecha Entrega")}`, field: "fechaEntregaReal", sortable: true },
    { name: `${t("Prioridad")}`, field: "prioridad", sortable: true },
    { name: `${t("Detalle Piezas")}`, field: "PDF", sortable: false },
    { name: `${t("Previo")}`, field: "previo", sortable: false },
    { name: `${t("PL")}`, field: "pl", sortable: false },
    { name: `${t("BL")}`, field: "bl", sortable: false },
    { name: `${t("Editar")}`, field: "editar", sortable: false },
    { name: `${t("Cancelar")}`, field: "cancelar", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.numeroReferencia
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.referenciaExtra.toLowerCase().includes(search.toLowerCase()) ||
          comment.referencia.toLowerCase().includes(search.toLowerCase()) ||
          comment.naviera.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.brokers.toLowerCase().includes(search.toLowerCase()) ||
          comment.forwarders.toLowerCase().includes(search.toLowerCase()) ||
          // comment.enMar.toLowerCase().includes(search.toLowerCase()) ||
          comment.surtido.toLowerCase().includes(search.toLowerCase()) ||
          comment.incrementable.toLowerCase().includes(search.toLowerCase()) ||
          comment.buque.toLowerCase().includes(search.toLowerCase()) ||
          comment.vessel.toLowerCase().includes(search.toLowerCase()) ||
          comment.totalCantidad.toString().includes(search) ||
          comment.prioridad.toString().includes(search) ||
          comment.totalGeneral.toString().includes(search) ||
          comment.flete.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }

    if (selectedBroker) {
      computedComments = computedComments.filter((e) =>
        e.idBrokers.includes(selectedBroker)
      );
    }

    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }

    if (selectedForwarder) {
      computedComments = computedComments.filter((e) =>
        e.idForwarders.includes(selectedForwarder)
      );
    }

    if (selectedNaviera) {
      computedComments = computedComments.filter((e) =>
        e.idNaviera.includes(selectedNaviera)
      );
    }

    if (selectedSurtido) {
      computedComments = computedComments.filter((e) =>
        e.surtido.includes(selectedSurtido)
      );
    }
    // if (selectedEnMar) {
    //   computedComments = computedComments.filter((e) =>
    //     e.enMar.includes(selectedEnMar)
    //   );
    // }

    if (selectedPrevio) {
      computedComments = computedComments.filter((e) =>
        e.previo.includes(selectedPrevio)
      );
    }
    if (selectedPL) {
      computedComments = computedComments.filter((e) =>
        e.packing.includes(selectedPL)
      );
    }
    if (selectedBL) {
      computedComments = computedComments.filter((e) =>
        e.bl.includes(selectedBL)
      );
    }
    if (selectedActivo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(selectedActivo)
      );
    }
    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "totalCantidad" &&
      sorting.field != "totalSurtido" &&
      sorting.field != "pendienteSurtir" &&
      sorting.field != "totalGeneral" &&
      sorting.field != "flete" &&
      sorting.field != "prioridad" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "totalCantidad" ||
        sorting.field != "totalSurtido" ||
        sorting.field != "pendienteSurtir" ||
        sorting.field == "totalGeneral" ||
        sorting.field == "flete" ||
        sorting.field == "prioridad" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "totalCantidad" ||
        sorting.field != "totalSurtido" ||
        sorting.field != "pendienteSurtir" ||
        sorting.field == "totalGeneral" ||
        sorting.field == "flete" ||
        sorting.field == "prioridad" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    selectedBroker,
    selectedCliente,
    selectedForwarder,
    selectedNaviera,
    selectedSurtido,
    // selectedEnMar,
    selectedPrevio,
    selectedPL,
    selectedBL,
    selectedActivo,
  ]);

  function EditArticulos(idOC, numeroContenedor) {
    setInputFields([
      {
        id: uuidv4(),
        articulos: "",
        descripcion: "",
        cantidad: 0,
        precio: 0,
        total: 0,
      },
    ]);
    setIdEdit(idOC);
    setNumeroContenedor(numeroContenedor);
    axios
      .get(`${URL_ARTICULOS_CONTENEDOR}/contenedor/${idOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosContenedor = response.data;
        let arrayTabla = allArticulosContenedor
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0]._id,
              cantidad: a.cantidad,
              descripcion: a.descripcion,
              precio: a.precio,
              total: a.total,
              pendienteSurtir: a.pendienteSurtir,
              cantidadSurtido: a.cantidadSurtido, descripcion: a.descripcion,
              proveedor: a.proveedor,
              marca: a.marca,
              PO: a.PO,
              CBMS: a.CBMS,
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setArticulosContenedor(dataFinal);

        let cant = dataFinal.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotalCantidadEdit(TC);
        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);
      })
      .catch((err) => {
        console.log(err);
      });
    toggleEditArticulos();
  }

  const handleChangeInputExistentes = (id, event) => {
    const newArticulosContenedor = articulosContenedor.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setArticulosContenedor(newArticulosContenedor);
    ActualizaTotales();
  };

  const handleChangeInputExistentesCantidad = (id, event) => {
    const newArticulosContenedor = articulosContenedor.map((i) => {
      if (id === i.id) {
        let PR =
          parseFloat(i.pendienteSurtir) -
          parseFloat(i.cantidad) +
          parseFloat(event.target.value);
        i.pendienteSurtir = PR;
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setArticulosContenedor(newArticulosContenedor);
    ActualizaTotales();
  };

  function BuscaArticuloExistentes(id, event) {
    articulos.map((a) => {
      if (a._id == event.target.value) {
        let idArticulo = a._id;
        handleChangeInputExistentesArticulo(id, idArticulo);
      }
    });
  }

  const handleChangeInputExistentesArticulo = (id, idArticulo) => {
    const newArticulosContenedor = articulosContenedor.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setArticulosContenedor(newArticulosContenedor);
    ActualizaTotales();
  };

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: ultimo.articulos,
        cantidad: 0,
        precio: ultimo.precio,
        total: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cantidadExistentes = articulosContenedor.map((c) =>
      parseFloat(c.cantidad)
    );
    let cantidadNuevos = values.map((c) => parseFloat(c.cantidad));
    let cantidad = cantidadExistentes.concat(cantidadNuevos);
    let TC = cantidad.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TC);

    let importesExistentes = articulosContenedor.map((c) =>
      parseFloat(c.total)
    );
    let importesNuevos = values.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    articulos.map((a) => {
      if (a._id == event.target.value) {
        let idArticulo = a._id;
        handleChangeInputArticulo(id, idArticulo);
      }
    });
  }

  const handleChangeInputArticulo = (id, idArticulo) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let cantidadExistentes = articulosContenedor.map((c) =>
      parseFloat(c.cantidad)
    );
    let cantidadNuevos = inputFields.map((c) => parseFloat(c.cantidad));
    let cantidad = cantidadExistentes.concat(cantidadNuevos);
    let TC = cantidad.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TC);

    let importesExistentes = articulosContenedor.map((c) =>
      parseFloat(c.total)
    );
    let importesNuevos = inputFields.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  }

  const saveContenedor = async (event) => {
    event.preventDefault();
    toggleProgreso();

    let totalArticulos = inputFields.length + articulosContenedor.length;

    const URL_CONTENEDORES_EDIT = `${process.env.REACT_APP_URL_CONTENEDORES}/${idEdit}`;
    try {
      await axios
        .patch(
          URL_CONTENEDORES_EDIT,
          {
            totalGeneral: totalGeneral_edit,
            totalCantidad: totalCantidad_edit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          inputFields.map((a) => {
            if (a.articulos != "" && a.cantidad > 0) {
              axios
                .post(
                  URL_ARTICULOS_CONTENEDOR,
                  {
                    contenedores: idEdit,
                    articulos: a.articulos,
                    descripcion: a.descripcion,
                    cantidad: parseFloat(a.cantidad),
                    pendienteSurtir: parseFloat(a.pendienteSurtir),
                    precio: parseFloat(a.precio),
                    total: parseFloat(a.total),
                    descripcion: a.descripcion,
                    proveedor: a.proveedor,
                    marca: a.marca,
                    PO: a.PO,
                    CBMS: a.CBMS,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Editar Contenedor",
                          detalle: `${numeroContenedor}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
              // cerrar if
            } else {
              totalArticulos = totalArticulos - 1;
              if (totalArticulos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Editar Contenedor",
                      detalle: `${numeroContenedor}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              }
            }
          });

          articulosContenedor.map((a) => {
            axios
              .patch(
                `${URL_ARTICULOS_CONTENEDOR}/${a.id}`,
                {
                  articulos: a.articulos,
                  cantidad: parseFloat(a.cantidad),
                  precio: parseFloat(a.precio),
                  total: parseFloat(a.total),
                  pendienteSurtir: parseFloat(a.pendienteSurtir),
                  descripcion: a.descripcion,
                  proveedor: a.proveedor,
                  marca: a.marca,
                  PO: a.PO,
                  CBMS: a.CBMS,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Editar Contenedor",
                        detalle: `${numeroContenedor}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  // function marcar(id) {
  //   Swal.fire({
  //     title: "Estas seguro?",
  //     text: "Se va a marcar!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Si, marcar!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       axios
  //         .patch(
  //           `${URL_CONTENEDORES}/${id}`,
  //           {
  //             enMar: "Si",
  //           },
  //           {
  //             headers: {
  //               Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //             },
  //           }
  //         )
  //         .then((response) => {
  //           Swal.fire({
  //             position: "center",
  //             icon: "success",
  //             title: "Se Actualizo",
  //             showConfirmButton: false,
  //             timer: 2000,
  //           });
  //           setTimeout(() => {
  //             window.location.reload();
  //           }, 1000);
  //         })
  //         .catch((error) => {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Oops...",
  //             text: "Something went wrong!",
  //             footer: `${error.response.data}`,
  //           });
  //           console.log(error);
  //         });
  //     }
  //   });
  // }

  // function desMarcar(id) {
  //   Swal.fire({
  //     title: "Estas seguro?",
  //     text: "Se quitara la facturacion!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Si, quitar!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       axios
  //         .patch(
  //           `${URL_CONTENEDORES}/${id}`,
  //           {
  //             enMar: "No",
  //           },
  //           {
  //             headers: {
  //               Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //             },
  //           }
  //         )
  //         .then((response) => {
  //           Swal.fire({
  //             position: "center",
  //             icon: "success",
  //             title: "Se actualizo",
  //             showConfirmButton: false,
  //             timer: 2000,
  //           });
  //           setTimeout(() => {
  //             window.location.reload();
  //           }, 1000);
  //         })
  //         .catch((error) => {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Oops...",
  //             text: "Something went wrong!",
  //             footer: `${error.response.data}`,
  //           });
  //           console.log(error);
  //         });
  //     }
  //   });
  // }

  function cancelarContenedor(idContenedor) {
    Swal.fire({
      title: `${t("Estas seguro?")}`,
      text: `${t("Se cancelara el Contenedor!")}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${t("Si, Cancelar!")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`${URL_CONTENEDORES}Cancel/${idContenedor}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then(() => {
            Swal.fire("Good job!", "Enviado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }
function guardaFotos() {
  const formData = new FormData();
  formData.append("file", file); // El archivo puede ser imagen, PDF, o Excel
  formData.append("contenedor", idContenedor);

  axios
    .post(URL_UPMEDIA, formData, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        "Content-Type": "multipart/form-data", // Asegura que el tipo de contenido sea correcto
      },
      params: {
        resource_type: "raw", // Para Cloudinary, permite la subida de archivos que no son imágenes
      },
    })
    .then((response) => {
      axios
        .patch(
          `${URL_CONTENEDORES}/${idContenedor}`,
          {
            previo: "Si",
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          axios
            .post(
              URL_LOGS,
              {
                tipo: "Contenedor",
                detalle: `Subir Foto`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then((response) => {
              Swal.fire("Good job!", "Actualizado con éxito", "success");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });

  setFile();
  setPhoto([]);
}


  function borrarFoto(id) {

    axios.post(`${URL_DELMEDIA}/${id}`, {}, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })

    .then(() => {

      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);

    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
    

    // setFile();
    // setPhoto([]);
  }
  function jalaFoto(idCont) {
    setPhoto([])
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_FINDMEDIABYTAG_CONTENEDORES}/${idCont}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        setPhoto(data.data.resources);
        // console.log("hola2", data.data.resources)
      })
      .catch((err) => console.log(err));
    setIdContenedor(idCont);


    toggleFoto();
  }

  // Foto Packing
  function guardaFotosPacking() {
    file.map((a)=>{
    const formData = new FormData();
    formData.append("file", a); // El archivo puede ser imagen, PDF, o Excel
    formData.append("contenedor", idContenedor);
  
    axios
      .post(URL_UPMEDIA_PACKING, formData, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          "Content-Type": "multipart/form-data", // Asegura que el tipo de contenido sea correcto
        },
        params: {
          resource_type: "raw", // Para Cloudinary, permite la subida de archivos que no son imágenes
        },
      })
      .then((response) => {
        axios
          .patch(
            `${URL_CONTENEDORES}/${idContenedor}`,
            {
              packing: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
              .post(
                URL_LOGS,
                {
                  tipo: "Contenedor",
                  detalle: `Subir Foto`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              )
              .then((response) => {
                Swal.fire("Good job!", "Actualizado con éxito", "success");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    })
    setFile();
    setPhoto([]);
  }

  function jalaFotoPacking(idCont) {
    setPhoto([])
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_FINDMEDIABYTAG_PACKING}/${idCont}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        setPhoto(data.data.resources);
        // console.log("hola2", data.data.resources)
      })
      .catch((err) => console.log(err));
    setIdContenedor(idCont);


    toggleFotoPacking();
  }
  function guardaFotosBL() {
    file.map((a)=>{
    const formData = new FormData();
    formData.append("file", a); // El archivo puede ser imagen, PDF, o Excel
    formData.append("contenedor", idContenedor);
  
    axios
      .post(URL_UPMEDIA_BL, formData, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          "Content-Type": "multipart/form-data", // Asegura que el tipo de contenido sea correcto
        },
        params: {
          resource_type: "raw", // Para Cloudinary, permite la subida de archivos que no son imágenes
        },
      })
      .then((response) => {
        axios
          .patch(
            `${URL_CONTENEDORES}/${idContenedor}`,
            {
              bl: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
              .post(
                URL_LOGS,
                {
                  tipo: "Contenedor",
                  detalle: `Subir Foto`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              )
              .then((response) => {
                Swal.fire("Good job!", "Actualizado con éxito", "success");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    })
    setFile();
    setPhoto([]);
  }

  function jalaFotoBL(idCont) {
    setPhoto([])
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_FINDMEDIABYTAG_BL}/${idCont}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        setPhoto(data.data.resources);
        // console.log("hola2", data.data.resources)
      })
      .catch((err) => console.log(err));
    setIdContenedor(idCont);


    toggleFotoBL();
  }
  async function registrarSG(id, idNaviera, contenedor) {

    axios
    .patch(
      `${URL_CONTENEDORES}AltaRastreo/${id}`,
      {
        navieras: idNaviera,
        contenedor: contenedor
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem(
            'app_token'
          )}`,
        },
      }
    )
    .then(() => {
      Swal.fire('Good job!', 'Creado con exito', 'success');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch((error) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
    
  }

  function crearMapa(polLatitude,
    polLongitude,
    podLatitude,
    podLongitude,
    vesselLatitude,
    vesselLongitude,
    etaReal
  ){

const etaRealDate = new Date(etaReal)
etaRealDate.setHours(0, 0, 0, 0);
hoy.setHours(0, 0, 0, 0);
  const diasDiferencia = Math.floor((Date.UTC(etaRealDate.getFullYear(), etaRealDate.getMonth(), etaRealDate.getDate()) - 
                                   Date.UTC(hoy.getFullYear(), hoy.getMonth(), hoy.getDate())) / (1000 * 60 * 60 * 24));

    setDiasLlegada(diasDiferencia)

    setOriginCoords([polLatitude, polLongitude]);  // Ningbo, China (puerto de origen)
    setVesselCoords([vesselLatitude, vesselLongitude]);  // Posición actual del barco
    setDestinationCoords([podLatitude, podLongitude]);  // Lázaro Cárdenas, México (puerto de destino)
  
    // Ruta con puntos intermedios
    setCoordenadasMapa([
      // [polLatitude, polLongitude], 
      [vesselLatitude, vesselLongitude], 
      [podLatitude, podLongitude],
      // [30, 140],  // Punto intermedio 1 en el océano
      // [40, -160],
      // [20, -130],  // Punto intermedio 2 en el océano
    ]);

    toggleMapa()
  }

  function EditStatus(
    id,
    status,
    mar,
    fechaMar,
    observacionesMar,
    piso,
    fechaPiso,
    observacionesPiso,
    revision,
    fechaRevision,
    observacionesRevision,
    modulacion,
    fechaModulacion,
    observacionesModulacion,
    libre,
    fechaLibre,
    observacionesLibre,
  ) {
    setId(id);
    setStatusSeguimiento(status);
    setMar(mar);
    setFechaMar(fechaMar);
    setObservacionesMar(observacionesMar);
    setPiso(piso);
    setFechaPiso(fechaPiso);
    setObservacionesPiso(observacionesPiso);
    setRevision(revision);
    setFechaRevision(fechaRevision);
    setObservacionesRevision(observacionesRevision);
    setModulacion(modulacion);
    setFechaModulacion(fechaModulacion);
    setObservacionesModulacion(observacionesModulacion);
    setLibre(libre);
    setFechaLibre(fechaLibre);
    setObservacionesLibre(observacionesLibre);
    toggleStatus();
  }
  function updateStatus(property, value, nombreFecha, fechaS, nomObservaciones, observaciones) {
    if (fechaS == '' || fechaS == undefined) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'La fecha no puede estar vacia',
      });
    } else {
      const data = {
        [property]: value,
        [nombreFecha]: fechaS,
        [nomObservaciones]: observaciones,
        statusSeguimiento,
      };

      axios
        .patch(`${URL_CONTENEDORES}/${id}`, data, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
          },
        })
        .then(() => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Actualizado con Exito',
            showConfirmButton: false,
            timer: 2000,
          });

          let temp = comments.map((a) => {
            if (a._id === id) {
              a[nomObservaciones] = observaciones;
              a[nombreFecha] = fechaS;
              a[property] = value;
              a.statusSeguimiento = statusSeguimiento;
            }
            return a;
          });
          setComments(temp);

          // setTimeout(() => {
          //   setModalStatus(false);
          // }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    }
  }

  function updateOnlyStatus() {
    axios
      .patch(
        `${URL_CONTENEDORES}/${id}`,
        {
          statusSeguimiento,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
          },
        }
      )
      .then(() => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Actualizado con Exito',
          showConfirmButton: false,
          timer: 2000,
        });

        let temp = comments.map((a) => {
          if (a._id === id) {
            a.statusSeguimiento = statusSeguimiento;
          }
          return a;
        });
        setComments(temp);

        setTimeout(() => {
          setModalStatus(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  

  return (
    <>
      <Header />
      <br />
      <br />
      {user.contenedoresCreatePS ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.contenedoresCreatePS ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ContenedoresCreate"
                >
                  {t("Nuevo")}
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  {t("Nuevo")}
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuContenedores"
                className="btn btn-danger"
                id="botonListado"
              >
                {t("Regresar")}
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                {/* <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={
                    e=>{
                      axios
                      .get(`${URL_ARTICULOS_CONTENEDOR}Desarrollo`, {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                        },
                      })
                      .then((response) => {
                        alert(response.data)
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                    }
                  }
                >
                  Desarrollo <i class="far fa-file-pdf"></i>
                </Button> */}
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>


                <Button
                  size="sm"
                  className="btn"
                  color="warning"
                  onClick={toggleFlete}
                >
                  Prioridad <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>{t("Enviar Listado Contenedores")}</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                {t("Enviar")}
              </Button>
            </ModalFooter>
            </Modal>

            <Modal size="sm" isOpen={modalFlete} toggle={toggleFlete}>
            <ModalHeader toggle={toggleFlete}>
              <h4>{t("Cargar Fletes")}</h4>
            </ModalHeader>
            <ModalBody>
              {/* <Label className="mr-sm-2">Cargar Fletes</Label> */}
               <Row>

            <Col>
                    <Input
                      required
                      type="file"
                      name="file"
                      id="file"
                      onChange={(event) =>
                        importarArchivo(event)
                      }
                    />
                    </Col>
            </Row>
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={guardaFlete}>
                {t("Guardar")}
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">{t("Contenedores")} Price Shoes</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">{t("Fecha Inicio")}</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">{t("Fecha Fin")}</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            {/* <Col md={2}>
              <Label className="mr-sm-2">{t("Previo")}</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedPrevio}
                onChange={(e) => {
                  setSelectedPrevio(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">{t("Selecciona")}</option>
                <option value="Si">{t("Si")}</option>
                <option value="No">{t("No")}</option>
              </Input>
            </Col> */}
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                  bsSize="sm"
                  type="select"
                  value={selectedNaviera}
                  onChange={(e) => {
                    setSelectedNaviera(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">{t("Selecciona")}</option>
                  {navieras
                    .sort((a, b) =>
                      a.name > b.name ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.name}</option>
                      );
                    })}
                </Input>
              </td>
              <td></td>
              {/* <td></td> */}
              <td></td>
              {/* <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCliente}
                  onChange={(e) => {
                    setSelectedCliente(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">{t("Selecciona")}</option>
                  {clientes
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </td> */}

              {/* {user.tipo == "Broker" ? (
                <td></td>
              ) : (
                <td style={{ paddingTop: "0px" }}>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedBroker}
                    onChange={(e) => {
                      setSelectedBroker(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">{t("Selecciona")}</option>
                    {brokers
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.name}</option>;
                      })}
                  </Input>
                </td>
              )} */}

              {user.tipo == "Forwarder" ? (
                <td></td>
              ) : (
                <td style={{ paddingTop: "0px" }}>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedForwarder}
                    onChange={(e) => {
                      setSelectedForwarder(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">{t("Selecciona")}</option>
                    {forwarders
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        if (user.tipo == "Forwarder") {
                          if (a._id == user.forwarders) {
                            return <option value={a._id}>{a.name}</option>;
                          }
                        } else {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                </td>
              )}

              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {/* <td></td>
              <td></td> */}
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedSurtido}
                  onChange={(e) => {
                    setSelectedSurtido(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">{t("Selecciona")}</option>
                  <option value="Si">{t("Si")}</option>
                  <option value="No">{t("No")}</option>
                </Input>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedPrevio}
                onChange={(e) => {
                  setSelectedPrevio(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">{t("Selecciona")}</option>
                <option value="Si">{t("Si")}</option>
                <option value="No">{t("No")}</option>
              </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedPL}
                onChange={(e) => {
                  setSelectedPL(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">{t("Selecciona")}</option>
                <option value="Si">{t("Si")}</option>
                <option value="No">{t("No")}</option>
              </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedBL}
                onChange={(e) => {
                  setSelectedBL(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">{t("Selecciona")}</option>
                <option value="Si">{t("Si")}</option>
                <option value="No">{t("No")}</option>
              </Input>
              </td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedActivo}
                onChange={(e) => {
                  setSelectedActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">{t("Selecciona")}</option>
                <option value="Si">{t("Si")}</option>
                <option value="No">{t("No")}</option>
              </Input>
              </td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                if (a.activo == "No") {
                  return (
                    <tr style={{ backgroundColor: "#ed8c8c" }}>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.numeroReferencia}</td>
                      <td>{a.referenciaExtra}</td>
                      <td>{a.referencia}</td>
                      <td>{a.naviera}</td>
                      <td>{a.buque}</td>
                      <td>{a.vessel}</td>
                      {/* <td>{a.clientes}</td> */}
                      {/* <td>{a.brokers}</td> */}
                      <td>{a.forwarders}</td>
                      <td>{a.etd}</td>
                      <td>{a.etaEstimada}</td>
                      <td>{a.etaReal}</td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.totalCantidad)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.flete)}
                      </td>
                      <td></td>
                      {/* <td>
                        {new Intl.NumberFormat("en-US").format(
                          a.totalSurtido
                        )}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(
                          a.pendienteSurtir
                        )}
                      </td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(
                            a.totalGeneral
                          )}{" "}
                      </td> */}
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {/* <td></td>
                      <td></td> */}
                      <td></td>
                      <td>CANCELADO</td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                } else {
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.numeroReferencia}</td>
                      <td>{a.referenciaExtra}</td>
                      <td>{a.referencia}</td>
                      <td>{a.naviera}</td>
                      <td>{a.buque}</td>
                      <td>{a.vessel}</td>
                      {/* <td>{a.clientes}</td> */}
                      {/* <td>{a.brokers}</td> */}
                      <td>{a.forwarders}</td>
                      <td>{a.etd}</td>
                      <td>{a.etaEstimada}</td>
                      <td>{a.etaReal}</td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.totalCantidad)}
                      </td>
                      {/* <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.flete)}
                      </td> */}
                      {/* <td>{a.incrementable}</td> */}
                      {/* <td>
                        {new Intl.NumberFormat("en-US").format(
                          a.totalSurtido
                        )}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(
                          a.pendienteSurtir
                        )}
                      </td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(
                            a.totalGeneral
                          )}{" "}
                      </td> 
                      <td>
                        <>
                          {a.enMar}{" "}
                          {a.enMar == "No" ? (
                            <Button
                              color="primary"
                              onClick={(e) => {
                                marcar(a._id);
                              }}
                              size="sm"
                            >
                              <i class="far fa-check-circle"></i>
                            </Button>
                          ) : (
                            <Button
                              color="danger"
                              onClick={(e) => {
                                desMarcar(a._id);
                              }}
                              size="sm"
                            >
                              <i class="far fa-times-circle"></i>
                            </Button>
                          )}
                        </>
                      </td>*/}
                      <td>{a.surtido}</td>
                      <td>{a.statusSeguimiento}</td>
                      <td>{a.fechaEntregaReal}</td>
                      <td>{a.prioridad}</td>
                      {user.tipo != "Broker" ? (
                        <>
                          <td>
                            <>
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                onClick={(e) =>
                                  PDFOC(
                                    a.fecha,
                                    a.clientes,
                                    a.brokers,
                                    a.forwarders,
                                    a.totalCantidad,
                                    a.totalGeneral,
                                    a.observaciones,
                                    a.numero,
                                    a.naviera,
                                    a._id
                                  )
                                }
                              >
                                <i class="far fa-file-pdf"></i>
                              </Button>

                              {a.rastreo == 'No' && a.idNaviera != "65a093c4b196000019bbeca4" ? (
                      <Button
                      size='sm'
                        color='success'
                        onClick={(e) =>{
                          registrarSG(a._id, a.idNaviera, a.contenedor)
                        }
                        }
                      >
                        SG
                      </Button>
                      ):(
                        <Button
                          size='sm'
                        color='success'
                          disabled
                          >
                            SG
                          </Button>)}
                      {a.mapa !== 'No' ? (
                      <Button
                      size='sm'
                        onClick={(e) =>{
                          crearMapa(a.polLatitude,
                            a.polLongitude,
                            a.podLatitude,
                            a.podLongitude,
                            a.vesselLatitude,
                            a.vesselLongitude,
                            a.etaReal
                          )
                        }
                        }
                      >
                        <i class='fas fa-map'></i>
                      </Button>
                      ):(
                        <Button
                          size='sm'
                          disabled
                          >
                            <i class='fas fa-map'></i>
                          </Button>)}

                            </>
                          </td>
                          <td>
                            <>
                              {a.previo == "Si" ? (
                                <>
                                {/* {a.previo} */}
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) => jalaFoto(a._id)}
                                >
                                  <i class="fas fa-camera"></i>
                                </Button>
                                </>
                              ) : (
                                <>
                                {/* {a.previo} */}
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="fas fa-camera"></i>
                                </Button>
                                </>
                              )}
                              {user.contenedoresCreatePS ? (
                                <Button
                                  color="success"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) => {
                                    setIdContenedor(a._id);
                                    // setIdControlCalidad(a.idControlCalidad)
                                    toggleSubirFoto();
                                  }}
                                >
                                  <i class="fas fa-upload"></i>
                                </Button>
                              ) : (
                                <Button
                                  color="success"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="fas fa-upload"></i>
                                </Button>
                              )}
                            </>
                          </td>
                          <td>
                            <>
                              {a.packing == "Si" ? (
                                <>
                                {/* {a.packing} */}
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) => jalaFotoPacking(a._id)}
                                >
                                  <i class="fas fa-camera"></i>
                                </Button>
                                </>
                              ) : (
                                <>
                                {/* {a.packing} */}
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="fas fa-camera"></i>
                                </Button>
                                </>
                              )}
                              {user.contenedoresCreatePS ? (
                                <Button
                                  color="success"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) => {
                                    setIdContenedor(a._id);
                                    // setIdControlCalidad(a.idControlCalidad)
                                    toggleSubirFotoPacking();
                                  }}
                                >
                                  <i class="fas fa-upload"></i>
                                </Button>
                              ) : (
                                <Button
                                  color="success"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="fas fa-upload"></i>
                                </Button>
                              )}
                            </>
                          </td>
                          <td>
                            <>
                              {a.bl == "Si" ? (
                                <>
                                {/* {a.bl} */}
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) => jalaFotoBL(a._id)}
                                >
                                  <i class="fas fa-camera"></i>
                                </Button>
                                </>
                              ) : (
                                <>
                                {/* {a.bl} */}
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="fas fa-camera"></i>
                                </Button>
                                </>
                              )}
                              {user.contenedoresCreatePS ? (
                                <Button
                                  color="success"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) => {
                                    setIdContenedor(a._id);
                                    // setIdControlCalidad(a.idControlCalidad)
                                    toggleSubirFotoBL();
                                  }}
                                >
                                  <i class="fas fa-upload"></i>
                                </Button>
                              ) : (
                                <Button
                                  color="success"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="fas fa-upload"></i>
                                </Button>
                              )}
                            </>
                          </td>
                          <td>
                              {user.contenedoresCreatePS ? (
                                <>
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) =>
                                    jalaInfo(
                                      a.fecha,
                                      a.idClientes,
                                      a.idBrokers,
                                      a.idForwarders,
                                      a.totalCantidad,
                                      a.totalGeneral,
                                      a.observaciones,
                                      a.numero,
                                      a.numeroReferencia,
                                      a.referenciaExtra,
                                      a.flete,
                                      a.incrementable,
                                      a.idNaviera,
                                      a.buque,
                                      a.prioridad,
                                      a.referencia,
                                      a.etd,
                                      a.etaEstimada,
                                      a.etaReal,
                                      a.fechaLlegada,
                                      a.fechaLiberacion,
                                      a.fechaEntregaEstimada,
                                      a.fechaEntregaReal,
                                      a.aduana,
                                      a.rastreo,
                                      a._id
                                    )
                                  }
                                >
                                  <i class="far fa-edit"></i>
                                </Button>


                              <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                EditArticulos(
                                  a._id,
                                  a.referencia,
                                )
                              }
                            >
                              <i class="fas fa-cart-plus"></i>
                            </Button>

                            <Button
                        color='success'
                        id='Editar'
                        size='sm'
                        onClick={(e) =>
                          EditStatus(
                            a._id,
                            a.statusSeguimiento,
                            a.mar,
                            a.fechaMar,
                            a.observacionesMar,
                            a.piso,
                            a.fechaPiso,
                            a.observacionesPiso,
                            a.revision,
                            a.fechaRevision,
                            a.observacionesRevision,
                            a.modulacion,
                            a.fechaModulacion,
                            a.observacionesModulacion,
                            a.libre,
                            a.fechaLibre,
                            a.observacionesLibre,
                          )
                        }
                      >
                        <i class='fas fa-calendar-check'></i>
                      </Button>
                                </>
                              ) : (
                                <>
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="far fa-edit"></i>
                                </Button>

                              <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              disabled
                            >
                              <i class="fas fa-cart-plus"></i>
                            </Button>

                            <Button
                        color='success'
                        id='Editar'
                        size='sm'
                        disabled
                      >
                        <i class='fas fa-calendar-check'></i>
                      </Button>
                            </>
                              )}
                          </td>
                          <td>
                            {a.surtido == "Si" ||
                            a.totalGasto > 0 ||
                            a.totalSurtido > 0 ||
                            !user.contenedoresCreatePS ? (
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                disabled
                              >
                                <i class="fas fa-ban"></i>
                              </Button>
                            ) : (
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                onClick={(e) => cancelarContenedor(a._id)}
                              >
                                <i class="fas fa-ban"></i>
                              </Button>
                            )}
                          </td>
                        </>
                      ) : (
                        <>
                          <td>
                            <>
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                disabled
                              >
                                <i class="far fa-file-pdf"></i>
                              </Button>
                              {a.previo == "Si" ? (
                                <>
                                {/* {a.previo} */}
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  disbaled
                                >
                                  <i class="fas fa-camera"></i>
                                </Button>
                                </>
                              ) : (
                                <>
                                {/* {a.previo} */}
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="fas fa-camera"></i>
                                </Button>
                                </>
                              )}
                              
                              <Button
                                color="success"
                                id="Editar"
                                size="sm"
                                disabled
                              >
                                <i class="fas fa-upload"></i>
                              </Button>
                            </>
                          </td>

                          <td>
                              {user.contenedoresCreatePS ? (
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) =>
                                    jalaInfo(
                                      a.fecha,
                                      a.idClientes,
                                      a.idBrokers,
                                      a.idForwarders,
                                      a.totalCantidad,
                                      a.totalGeneral,
                                      a.observaciones,
                                      a.numero,
                                      a.numeroReferencia,
                                      a.referenciaExtra,
                                      a.flete,
                                      a.incrementable,
                                      a.idNaviera,
                                      a.buque,
                                      a.prioridad,
                                      a.referencia,
                                      a.etd,
                                      a.etaEstimada,
                                      a.etaReal,
                                      a.fechaLlegada,
                                      a.fechaLiberacion,
                                      a.fechaEntregaEstimada,
                                      a.fechaEntregaReal,
                                      a.aduana,
                                      a._id
                                    )
                                  }
                                >
                                  <i class="far fa-edit"></i>
                                </Button>
                              ) : (
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="far fa-edit"></i>
                                </Button>
                              )}

                              <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                disabled
                              >
                                <i class="fas fa-cart-plus"></i>
                              </Button>
                          </td>
                          <td>
                            {a.surtido == "Si" ||
                            a.totalGasto > 0 ||
                            a.totalSurtido > 0 ? (
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                disabled
                              >
                                <i class="fas fa-ban"></i>
                              </Button>
                            ) : (
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                disabled
                              >
                                <i class="fas fa-ban"></i>
                              </Button>
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="xl" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            {t("Editar")} {t("Contenedor")} {numeroContenedor}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>{t("Fecha")}</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
            </Col>

            <Col md={3}>
              <Label>{t("Numero de Referencia")}</Label>
              <Input
                type="text"
                placeholder="NumeroReferencia"
                value={numeroReferencia}
                required
                onChange={(e) => {
                  setNumeroReferencia(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>{t("Referencia Extra")}</Label>
              <Input
                type="text"
                placeholder="ReferenciaExtra"
                value={referenciaExtra}
                required
                onChange={(e) => {
                  setReferenciaExtra(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">{t("Contenedor")}</Label>
              <Input
                type="text"
                placeholder="Referencia"
                value={referencia}
                required
                onChange={(e) => {
                  setReferencia(e.target.value);
                }}
              />
            </Col>
            </Row>
            <br />
            <Row>
            <Col md={3}>
              <Label className="mr-sm-2">{t("Naviera")}</Label>
              { rastreo == "No" ? (
                 <Input
                 className="col-sm-12"
                 type="select"
                 value={selectedNavieraEdit}
                 required
                 onChange={(e) => {
                   setSelectedNavieraEdit(e.target.value);
                 }}
               >
                 <option value="0">{t("Selecciona")}</option>
                 {navieras
                   .sort((a, b) =>
                     a.name > b.name ? 1 : -1
                   )
                   .map((a) => {
                     return <option value={a._id}>{a.name}</option>;
                   })}
               </Input>
              ):(
                <Input
                className="col-sm-12"
                type="select"
                value={selectedNavieraEdit}
                required
                disabled
              >
                <option value="0">{t("Selecciona")}</option>
                {navieras
                  .sort((a, b) =>
                    a.name > b.name ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
              )}
              
            </Col>
            {/* <Col md={3}>
              <Label className="mr-sm-2">{t("Cliente")}</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedClienteEdit}
                required
                disabled
              />
            </Col> */}

            {/* <Col md={3}>
              <Label className="mr-sm-2">Broker</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedBrokerEdit}
                required
                onChange={(e) => {
                  setSelectedBrokerEdit(e.target.value);
                }}
              >
                <option value="0">{t("Selecciona")}</option>
                {brokers
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col> */}
 <Col md={3}>
              <Label>{t("Buque")}</Label>
              <Input
                type="text"
                placeholder="buque"
                value={buque}
                required
                onChange={(e) => {
                  setBuque(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>{t("Prioridad")}</Label>
              <Input
                type="number"
                placeholder="Prioridad"
                value={prioridad}
                required
                onChange={(e) => {
                  setPrioridad(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Forwarder</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedForwarderEdit}
                required
                onChange={(e) => {
                  setSelectedForwarderEdit(e.target.value);
                }}
              >
                <option value="0">{t("Selecciona")}</option>
                {forwarders
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <Label>ETD</Label>
              <Input
                type="date"
                placeholder="Fecha ETD"
                value={fechaETD}
                required
                onChange={(e) => {
                  setFechaETD(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>ETA {t("Puerto")}</Label>
              <Input
                type="date"
                value={fechaETAEstimada}
                required
                onChange={(e) => {
                  setFechaETAEstimada(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Arribo Real Puerto</Label>
              <Input
                type="date"
                value={fechaETAReal}
                required
                onChange={(e) => {
                  setFechaETAReal(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>{t("Liberacion Aduana")}</Label>
              <Input
                type="select"
                value={aduana}
                required
                onChange={(e) => {
                  setAduana(e.target.value);
                }}
              >
                <option value="No">No</option>
                <option value="Si">{t("Si")}</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Row>
            {/* <Col md={3}>
                <Label>Fecha Liberacion</Label>
                <Input
                  type="date"
                  value={fechaLiberacion}
                  required
                  onChange={(e) => {
                    setFechaLiberacion(e.target.value);
                  }}
                />
              </Col> */}
            {/* <Col md={3}>
              <Label>{t("Fecha")} {t("Entrega")} {t("Estimada")}</Label>
                <Input
                  type="date"
                  value={fechaEntregaEstimada}
                  required
                  onChange={(e) => {
                    setFechaEntregaEstimada(e.target.value);
                  }}
                />
              </Col> */}
              
            {/* <Col md={3}>
              <Label>
                {t("Fecha")} {t("Entrega")} Real
              </Label>
              <Input
                type="date"
                value={fechaEntregaReal}
                required
                onChange={(e) => {
                  setFechaEntregaReal(e.target.value);
                }}
              />
            </Col> */}
            {/* <Col md={3}>
              <Label>{t("Flete")}</Label>
              <Input
                type="number"
                placeholder="Flete"
                value={flete}
                required
                onChange={(e) => {
                  setFlete(e.target.value);
                }}
              />
            </Col> */}
            {/* <Col md={2}>
                <Label>{t("Incrementable")}</Label>
                <Input
                  type="select"
                  placeholder="Incrementable"
                  value={incrementable}
                  required
                  onChange={(e) => {
                    setIncrementable(e.target.value);
                  }}
                >
                 <option value="">{t("Selecciona")}</option>
                 <option value="Si">{t("Si")}</option>
                 <option value="No">{t("No")}</option>
                  
                </Input> 
              </Col> */}
          {/* </Row>
          <br />
          <Row> */}
         
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                {t("Observaciones")}
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editContenedores}>
            {t("Guardar")}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalEditArticulos}
        toggle={toggleEditArticulos}
      >
        <ModalHeader toggle={toggleEditArticulos}>
          <h4>
            {"Editar"} {t("Articulos")} {t("Contenedor")} {numeroContenedor}
          </h4>
          <h4>
            TOTAL {totalCantidad_edit} pzas. /{" "}
            {"$" + new Intl.NumberFormat("en-US").format(totalGeneral_edit)}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">{t("Articulos")}</Label>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">{t("Descripcion")}</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">{t("Proveedor")}</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">{t("Marca")}</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">{t("PO")}</Label>
            </Col>

            <Col md={1}>
              <Label className="mr-sm-2">{t("CBM")}</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">{t("Cantidad")}</Label>
            </Col>
            {/* <Col md={1}>
              <Label className="mr-sm-2">{t("Surtido")}</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">{t("Por Surtir")}</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">{t("Precio")}</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Total</Label>
            </Col> */}
          </Row>

          {articulosContenedor.map((ao) => (
            <div key={ao.id}>
              <Row>
                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name="articulos"
                    type="select"
                    value={ao.articulos}
                    required
                    onChange={(event) => {
                      BuscaArticuloExistentes(ao.id, event);
                    }}
                  >
                    <option value="0">{t("Selecciona")}</option>
                    {articulos
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.name}</option>;
                      })}
                  </Input>
                </Col>

                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name="descripcion"
                    type="text"
                    placeholder="descripcion"
                    value={ao.descripcion}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentes(ao.id, event)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="proveedor"
                    type="text"
                    placeholder="proveedor"
                    value={ao.proveedor}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentes(ao.id, event)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="marca"
                    type="text"
                    placeholder="marca"
                    value={ao.marca}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentes(ao.id, event)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="PO"
                    type="number"
                    placeholder="PO"
                    value={ao.PO}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentes(ao.id, event)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="CBMS"
                    type="number"
                    placeholder="CBMS"
                    value={ao.CBMS}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentes(ao.id, event)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="cantidad"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Cantidad"
                    value={ao.cantidad}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentesCantidad(ao.id, event)
                    }
                  />
                </Col>
                {/* <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="surtido"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="surtido"
                    value={ao.cantidadSurtido}
                    required
                    disabled
                  />
                </Col>
                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="pendienteSurtir"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="pendienteSurtir"
                    value={ao.pendienteSurtir}
                    required
                    disabled
                  />
                </Col>

                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="precio"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Precio"
                    value={ao.precio}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentes(ao.id, event)
                    }
                  />
                </Col>
                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name="total"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Total"
                    value={ao.total}
                    disabled
                    onChange={(event) =>
                      handleChangeInputExistentes(ao.id, event)
                    }
                  />
                </Col> */}
              </Row>
            </div>
          ))}

          {/* Agregar mas articulos */}

          {inputFields.map((inputField) => (
            <div key={inputField.id}>
              <Row>
                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name="articulos"
                    type="select"
                    value={inputField.articulos}
                    required
                    onChange={(event) => {
                      BuscaArticulo(inputField.id, event);
                    }}
                  >
                    <option value="0">{t("Selecciona")}</option>
                    {articulos
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.name}</option>;
                      })}
                  </Input>
                </Col>

                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name="descripcion"
                    type="text"
                    placeholder="descripcion"
                    value={inputField.descripcion}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>
                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="proveedor"
                    type="text"
                    placeholder="proveedor"
                    value={inputField.proveedor}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>
                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="marca"
                    type="text"
                    placeholder="marca"
                    value={inputField.marca}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>
                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="PO"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="PO"
                    value={inputField.PO}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>
                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="CBMS"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="CBMS"
                    value={inputField.CBMS}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="cantidad"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Cantidad"
                    value={inputField.cantidad}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>
                {/* <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="surtido"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="NA"
                    value={inputField.cantidadSurtido}
                    required
                    disabled
                  />
                </Col>
                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="pendienteSurtir"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="NA"
                    value={inputField.pendienteSurtir}
                    required
                    disabled
                  />
                </Col>

                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="precio"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Precio"
                    value={inputField.precio}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>

                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name="total"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Total"
                    value={inputField.total}
                    disabled
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col> */}
                <Col>
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    disabled={inputFields.length === 1}
                    onClick={() => handleRemoveFields(inputField.id)}
                    tabindex="-1"
                  >
                    <i class="fas fa-minus"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    color="info"
                    onClick={handleAddFields}
                    tabindex="-1"
                  >
                    <i class="fas fa-plus"></i>
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          <Row>
            <Col md={6}>
              <h4 id="logoutBoton">
                TOTAL {totalCantidad_edit} pzas. /{" "}
                {"$" + new Intl.NumberFormat("en-US").format(totalGeneral_edit)}
              </h4>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={saveContenedor}>
            {t("Guardar")}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>{t("Progreso")}</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            {t("Este proceso puede tardar varios segundos.")}
            <br />
            {t("Por favor no cierre ni refresque su navegador.")}
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalSubirFoto} toggle={toggleSubirFoto}>
        <ModalHeader toggle={toggleSubirFoto}>
          <h4>Subir Fotos</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <Label>Selecciona un archivo</Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>
          </Row>
          <Button size="sm" color="success" onClick={guardaFotos}>
            Guardar
          </Button>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalSubirFotoPacking} toggle={toggleSubirFotoPacking}>
        <ModalHeader toggle={toggleSubirFotoPacking}>
          <h4>Subir Fotos</h4>
        </ModalHeader>
        <ModalBody>
        <Row>
                  <Col md={12}>
              <Label>
                Selecciona los Archivos
              </Label>
              <Input
                type="file"
                multiple
                onChange={(e) => {
                  setFile(Array.from(e.target.files));
                  // setFile(e.target.files[0]);
                }}
              />
            </Col>
                  </Row>
          <Button size="sm" color="success" onClick={guardaFotosPacking}>
            Guardar
          </Button>
        </ModalBody>
      </Modal>
      <Modal size="sm" isOpen={modalSubirFotoBL} toggle={toggleSubirFotoBL}>
        <ModalHeader toggle={toggleSubirFotoBL}>
          <h4>Subir Fotos</h4>
        </ModalHeader>
        <ModalBody>
        <Row>
                  <Col md={12}>
              <Label>
                Selecciona los Archivos
              </Label>
              <Input
                type="file"
                multiple
                onChange={(e) => {
                  setFile(Array.from(e.target.files));
                  // setFile(e.target.files[0]);
                }}
              />
            </Col>
                  </Row>
          <Button size="sm" color="success" onClick={guardaFotosBL}>
            Guardar
          </Button>
        </ModalBody>
      </Modal>

      <Modal size="xxl" isOpen={modalFoto} toggle={toggleFoto}>
  <ModalHeader toggle={toggleFoto}>
    <h4>Fotos</h4>
  </ModalHeader>
  <ModalBody>
    <Row>
      {photo.length > 0 ? (
        photo.map((item) => (
          <div key={item.id} style={{ textAlign: "center", marginBottom: "20px" }}>
            {item.format === "pdf" ? (
              <embed
                src={item.secure_url}
                type="application/pdf"
                width="500"
                height="600"
              />
            ) : item.format === "xls" || item.format === "xlsx" || item.format === "xlsm" ? (
              <a href={item.secure_url} download>
                <Button color="primary">
                  Descargar {item.original_filename}. {item.format}
                </Button>
              </a>
            ) : (
              <img
                src={item.secure_url}
                alt="Uploaded Image"
                width="250"
                height="auto"
              />
            )}
            <div>
              <Button
                color="danger"
                onClick={() => borrarFoto(item.asset_id)}
                style={{ marginTop: "10px" }}
              >
                Eliminar
              </Button>
            </div>
          </div>
        ))
      ) : (
        <h4>No se ha subido una Foto, PDF o archivo Excel....</h4>
      )}
    </Row>
  </ModalBody>
</Modal>

    
    <Modal size="xxl" isOpen={modalFotoPacking} toggle={toggleFotoPacking}>
      <ModalHeader toggle={toggleFotoPacking}>
        <h4>Packing List</h4>
      </ModalHeader>
      <ModalBody>
      <Row>
      {photo.length > 0 ? (
        photo.map((item) => (
          <div key={item.id} style={{ textAlign: "center", marginBottom: "20px" }}>
            {item.format === "pdf" ? (
              <embed
                src={item.secure_url}
                type="application/pdf"
                width="500"
                height="600"
              />
            ) : item.format === "xls" || item.format === "xlsx" || item.format === "xlsm" ? (
              <a href={item.secure_url} download>
                <Button color="primary">
                  Descargar {item.original_filename}. {item.format}
                </Button>
              </a>
            ) : (
              <img
                src={item.secure_url}
                alt="Uploaded Image"
                width="250"
                height="auto"
              />
            )}
            <div>
              <Button
                color="danger"
                onClick={() => borrarFoto(item.asset_id)}
                style={{ marginTop: "10px" }}
              >
                Eliminar
              </Button>
            </div>
          </div>
        ))
      ) : (
        <h4>No se ha subido una Foto, PDF o archivo Excel....</h4>
      )}
    </Row>
      </ModalBody>
    </Modal>

    <Modal size="xxl" isOpen={modalFotoBL} toggle={toggleFotoBL}>
      <ModalHeader toggle={toggleFotoBL}>
        <h4>BL</h4>
      </ModalHeader>
      <ModalBody>
      <Row>
      {photo.length > 0 ? (
        photo.map((item) => (
          <div key={item.id} style={{ textAlign: "center", marginBottom: "20px" }}>
            {item.format === "pdf" ? (
              <embed
                src={item.secure_url}
                type="application/pdf"
                width="500"
                height="600"
              />
            ) : item.format === "xls" || item.format === "xlsx" || item.format === "xlsm" ? (
              <a href={item.secure_url} download>
                <Button color="primary">
                  Descargar {item.original_filename}. {item.format}
                </Button>
              </a>
            ) : (
              <img
                src={item.secure_url}
                alt="Uploaded Image"
                width="250"
                height="auto"
              />
            )}
            <div>
              <Button
                color="danger"
                onClick={() => borrarFoto(item.asset_id)}
                style={{ marginTop: "10px" }}
              >
                Eliminar
              </Button>
            </div>
          </div>
        ))
      ) : (
        <h4>No se ha subido una Foto, PDF o archivo Excel....</h4>
      )}
    </Row>
      </ModalBody>
    </Modal>
      <Modal size='xl' isOpen={modalMapa} toggle={toggleMapa}>
        <ModalHeader toggle={toggleMapa}>
          <h4>Trayectoria del Contenedor. Faltan {diasLlegada} dias para la llegada</h4>
          </ModalHeader>
      <ModalBody>
      <MapContainer 
          center={vesselCoords}  // Cambia el centro al océano Pacífico, lo que coloca Asia a la izquierda y América a la derecha
          zoom={3} 
          style={{ height: "400px", width: "100%" }}
          maxBounds={[[85, -180], [-85, 180]]}  // Limita el mapa a un único globo terráqueo
          maxBoundsViscosity={1.0}  // Impide que se salga de los límites
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            noWrap={true}  // Evita que el mapa se "envuelva"
          />

          {/* Marcadores para el origen, posición actual y destino */}
          <Marker position={destinationCoords}></Marker>
          <Marker position={vesselCoords}></Marker>
          <Marker position={originCoords}></Marker>

          {/* Línea de trayectoria */}
          {/* <Polyline positions={coordenadasMapa} color="blue" />
           */}
           <Polyline positions={[vesselCoords, destinationCoords]} color="blue" dashArray="5,10" />
        </MapContainer>
      </ModalBody>
      </Modal>

      <Modal size='xl' isOpen={modalStatus} toggle={toggleStatus}>
        <ModalHeader toggle={toggleStatus}>
          <h4>
            {t('Status')} Contenedor
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={4}>
              <Label>Status</Label>
              <Input
                type='select'
                value={statusSeguimiento}
                onChange={(e) => setStatusSeguimiento(e.target.value)}
              >
                <option value='Mar'>Mar</option>
                <option value='Piso'>Piso</option>
                <option value='Revision'>Revision</option>
                <option value='Modulacion'>Modulacion</option>
                <option value='Libre'>Libre</option>
              </Input>
              {/* </Col>
                <Col md={3}>
                  <Label>.</Label> */}
              <Button onClick={() => updateOnlyStatus()} size='sm'>
                Actualizar
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={2} className='negrita'>
              <Label className='mr-sm-2'>Concepto</Label>
            </Col>
            <Col md={3} className='negrita'>
              <Label className='mr-sm-2'>Fecha</Label>
            </Col>
            <Col md={3} className='negrita'>
              <Label className='mr-sm-2'>Observaciones</Label>
            </Col>
            <Col md={3} className='negrita'>
              <Label className='mr-sm-2'>Realizado</Label>
            </Col>
          </Row>

          <Row>
            <Col md={2}>
              <Label className='mr-sm-2'>Mar</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaMar}
                onChange={(e) => setFechaMar(e.target.value)}
              />
            </Col>

            <Col md={3} className='negro'>
              <Input
                type='text'
                value={observacionesMar}
                onChange={(e) => setObservacionesMar(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{mar === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={1}>
              <Button
                color={mar === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'mar',
                    mar === 'Si' ? 'No' : 'Si',
                    'fechaMar',
                    fechaMar,
                    'observacionesMar',
                    observacionesMar
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>

            
          </Row>
          <br />
          
          <Row>
            <Col md={2}>
              <Label className='mr-sm-2'>Piso</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaPiso}
                onChange={(e) => setFechaPiso(e.target.value)}
              />
            </Col>

            <Col md={3} className='negro'>
              <Input
                type='text'
                value={observacionesPiso}
                onChange={(e) => setObservacionesPiso(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{mar === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={1}>
              <Button
                color={piso === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'piso',
                    piso === 'Si' ? 'No' : 'Si',
                    'fechaPiso',
                    fechaPiso,
                    'observacionesPiso',
                    observacionesPiso
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>

            
          </Row>
          <br />

          <Row>
            <Col md={2}>
              <Label className='mr-sm-2'>Revision</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaRevision}
                onChange={(e) => setFechaRevision(e.target.value)}
              />
            </Col>

            <Col md={3} className='negro'>
              <Input
                type='text'
                value={observacionesRevision}
                onChange={(e) => setObservacionesRevision(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{mar === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={1}>
              <Button
                color={revision === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'revision',
                    revision === 'Si' ? 'No' : 'Si',
                    'fechaRevision',
                    fechaRevision,
                    'observacionesRevision',
                    observacionesRevision
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>

            
          </Row>
          <br />

          <Row>
            <Col md={2}>
              <Label className='mr-sm-2'>Modulacion</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaModulacion}
                onChange={(e) => setFechaModulacion(e.target.value)}
              />
            </Col>

            <Col md={3} className='negro'>
              <Input
                type='text'
                value={observacionesModulacion}
                onChange={(e) => setObservacionesModulacion(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{mar === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={1}>
              <Button
                color={modulacion === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'modulacion',
                    modulacion === 'Si' ? 'No' : 'Si',
                    'fechaModulacion',
                    fechaModulacion,
                    'observacionesModulacion',
                    observacionesModulacion
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>

            
          </Row>
          <br />

          <Row>
            <Col md={2}>
              <Label className='mr-sm-2'>Libre</Label>
            </Col>
            <Col md={3} className='negro'>
              <Input
                type='date'
                value={fechaLibre}
                onChange={(e) => setFechaLibre(e.target.value)}
              />
            </Col>

            <Col md={3} className='negro'>
              <Input
                type='text'
                value={observacionesLibre}
                onChange={(e) => setObservacionesLibre(e.target.value)}
              />
            </Col>

            <Col md={3}>
              <Label>{mar === 'Si' ? 'Listo' : 'Pendiente'}</Label>
            </Col>

            <Col md={1}>
              <Button
                color={libre === 'Si' ? 'primary' : 'danger'}
                onClick={() =>
                  updateStatus(
                    'libre',
                    libre === 'Si' ? 'No' : 'Si',
                    'fechaLibre',
                    fechaLibre,
                    'observacionesLibre',
                    observacionesLibre
                  )
                }
                size='sm'
              >
                <i className='fas fa-check-circle'></i>
              </Button>
            </Col>

            
          </Row>
          <br />

          <br />
          <br />
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoContenedoresPS;
